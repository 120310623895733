import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { getLink } from "../../utils/helper";
import CustomLink from "../CustomLink";
import { isJsonParsable } from "../meta";
import "./sidemenu.scss";
import { storyblokEditable } from "gatsby-source-storyblok"
const SideMenu = ({ content, lang, uuid }) => {
  const navItems = useStaticQuery(
    graphql`
          query sideMenu {
            main_nav :allStoryblokEntry(filter: { field_component: { eq: "NavItems" } }) {
              edges {
                node {
                  id
                  uuid
                  content
                  full_slug
                  slug
                  lang
                  internalId
                }
              }
            }
            sub_nav : allStoryblokEntry(filter: { field_component: { eq: "NavSubItem" } }) {
              edges {
                node {
                  id
                  uuid
                  content
                  full_slug
                  slug
                  lang
                  internalId
                }
              }
            }
          }
          
        `
  );

  const { main_nav, sub_nav } = navItems;
  const allNavData = main_nav?.edges?.filter(i => i?.node?.lang === lang).map((item) => {
    const Node_Content = isJsonParsable(item?.node?.content) && item?.node?.lang === lang ? JSON.parse(item?.node?.content) : null
    const subNavItem = Node_Content?.sub_items?.map((i) => sub_nav?.edges?.find((sub_i) => (sub_i?.node?.uuid === i && sub_i?.node?.lang === lang)));
    return { Node_Content, subNavItem }
  })
  const allSubItems = allNavData?.find(i => i?.subNavItem?.find(i => {

    if (isJsonParsable(i?.node?.content)) {
      return JSON.parse(i?.node?.content)?.Link?.id === uuid
    }
    return false
  }))
  return (
    <>
      <div className="SideNav" {...storyblokEditable(content || {})}>
        <ul>
          {allSubItems?.subNavItem.map((item, index) => {
            const content = isJsonParsable(item?.node?.content) ? JSON.parse(item?.node?.content) : null
            return (
              <li>
                <CustomLink key={index} {...content?.Link} activeClassName="active"> {content?.Title} </CustomLink>
              </li>
            )
          })}
        </ul>

      </div>
    </>
  )
}

export default SideMenu
import React, { useState } from "react"
import DynamicComponent from "./DynamicComponent"
import { sbEditable } from "@storyblok/storyblok-editable";
import { StoryblokComponent, storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"
import SideMenu from "./SideMenu";
import ContactPopup from "./ContactPopup";
import { Api } from "../api/genrated/services";

const Page = ({ blok, location , extra_content ,extra_classes='', uuid, fourofour}) => {
  const [isShow , setShow ]=useState(false)
  const content =
    Array.isArray(blok?.body) &&
    blok?.body.map(childBlok => <StoryblokComponent blok={childBlok} key={childBlok._uid} setShow={setShow} location={location} page_uuid = {uuid}/>)
    const hasTitle = blok?.title && blok?.title.length ? (<h4 className="subTitle">
    { blok?.title }
    </h4>) : null
  return (
    <div {...storyblokEditable(blok)} className={['/', '/en' , '/en/'].includes(location?.pathname || '') ? `indexWrap ${fourofour?'fourofour' :''} ${extra_classes}`:`innerPageWrap ${fourofour?'fourofour' :''} ${extra_classes}`}>
        { hasTitle }
        { content }
        { extra_content }
        {/* {isShow && <ContactPopup setShow={setShow} submit={submitHandler}/>} */}
    </div>
  )
}

export default Page
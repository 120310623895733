import React from "react"
import Page from '../components/Page'
import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import { useStoryblokState } from "gatsby-source-storyblok"


export default function PageIndex({ data, location }) {
    const story = useStoryblokState(data.story,location)
  
    return (
      <Layout location={location} full_slug={story?.full_slug} enableGetinTouch={story?.content?.get_in_touch_section} lang={story?.lang}>
        <Page blok={story.content} location={location} />
      </Layout>
    )
}

export const query = graphql`
  {
      story: storyblokEntry(full_slug: {eq: "home"}) {
      content
      field_component
      id
      full_slug
      lang
      name
      internalId
    }
  }
`